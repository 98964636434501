<template>
  <div class="wrap">
    <div class="header">
      <img src="/images/album/banner.png" class="town-pic"/>
      <img src="/images/album/title.png" class="title-img">
    </div>
    <div class="content">
      <h3 class="content-title">小镇相册</h3>
      <ul class="content-list">
        <li
          v-for="item in list"
          :key="item.id"
          class="list-item"
          @click="goPage(`/albumdetail?id=${item.id}&name=${item.name}`)"
        >
          <div class="item-cover">
            <img
                class="cover-img"
                :src="item.picture"
                alt=""
            />
            <div class="border-box">
              <img src="/images/album/border.png" class="border"/>
              <div class="text-box">
                <h3 class="item-title">
                  {{ item.name }}
                </h3>
              </div>
            </div>


          </div>

        </li>
      </ul>
      <NoData :visible="!list.length" />
      <div class="page">
        <Page
          class="page-tools"
          :size="params.pageSize"
          :current="pageNum"
          :total="total"
          @pageChange="pageChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import NoData from '@/components/common/nodata'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import Page from '@/components/common/pagination'
import { useList, useParams } from '@/hooks/useList'
import api from '@/api'
export default {
  name: 'album',
  components: {
    Page,
    NoData
  },
  setup () {
    const route = useRoute()
    const { params, search, } = useParams({
      pageNum: route.query?.pn ?? 1,
      pageSize: 9,
      tabs:'ALBUM'
    })
    const { total, pageNum, list, getList } = useList(params, api.ablumlist)
    onBeforeRouteUpdate((to) => {
      search({
        pageNum: to.query?.pn
      })
    })

    return {
      total,
      pageNum,
      params,
      list,
      getList,
      search
    }

  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    pageChange (page) {
      this.$router.push({
        query: {
          pn: page
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  display: flex;
  min-height: calc(100vh - 1.06rem);
  justify-content: center;

  background: #ffffff;
  flex-direction: column;
  align-items: center;
  .content {
    width: 12rem;
    min-height: calc(100vh - 1.06rem);
    padding: 0.6rem 0;
    .content-title {
      font-size: 0.3rem;
      font-weight: bold;
      color: #333333;
    }
    .content-subTitle {
      font-size: 0.14rem;
      font-weight: normal;
      color: #999999;
    }
    .content-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-top: 0.35rem;
      .list-item {
        position: relative;
        width: 3.9rem;
        height: 3.52rem;
        margin-bottom: 0.6rem;
        transition: all 300ms;
        cursor: pointer;
        &:not(:nth-of-type(3n)) {
          margin-right: 0.09rem;
        }
        //&:hover {
        //  transition: all 300ms;
        //  transform: scale(1.05);
        //  cursor: pointer;
        //}


        .item-cover {
          display: flex;
          width: 100%;
          height:100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          flex-direction: column;
          align-items: center;
          .cover-img {
            width: 100%;
            height:2.82rem;
            object-fit: cover;
          }
        }
        .text-box {
          position: absolute;
          width: 100%;
          height: 0.61rem;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        .item-title {
          font-size: 0.2rem;
          font-weight: normal;
          color: #000000;
          @extend .text-oneLine;
        }
      }
    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }
  .header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    width: 100%;

    .town-pic {
      width: 100%;
      height: 7.36rem;
    }

    .title-img {
      width: 6.4rem;
      height: 1.6rem;
      position: absolute;
    }
  }
  .border-box {
    display: flex;
    position: relative;
    .border {
      width: 3.9rem;
      height: 0.7rem;
    }
  }

}
</style>
